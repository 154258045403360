<template>
  <div class="quick-toolbar">
    <div class="wrapper">
      <p class="title">什么是快捷回复？</p>
      <p class="desc">
        EduSoho销客助手提供了快捷回复功能，帮助员工高效的与客户沟通。快捷回复包括企业话术、个人话术和商机雷达，并提供文字、图片、链接、PDF等多种类型的消息。
      </p>
    </div>

    <div class="wrapper">
      <div class="mb16">
        <span class="title">如何使用？</span>
        <router-link class="ml8" :to="{name: 'system_setting_chat_toolbar'}">配置教程</router-link>
      </div>
      <p class="desc">
        快捷回复配置成功后，员工登录企业微信，在与客户的聊天对话框右侧点击侧边栏的图标，即可看到快捷回复工具栏。
      </p>
    </div>

    <div class="wrapper">
      <a-image class="display-img"
        src="https://ese9a2b9c8d2vx-pub.pubssl.qiqiuyun.net/persondialogue/image/2017427023e3c5b673b2969d223b7692"
        alt="快捷回复图" />
    </div>
  </div>
</template>

<script>
import { Image } from "ant-design-vue";
export default {
  name: "QuickReply",
  components: {
    AImage: Image,
  },
};
</script>